import {AsperaWrapper, Button, InlineNotification, language, NotificationTypes, openNewTab, tracking} from '@aspera-ui/starter';
import {Connect, Logger} from '@ibm-aspera/connect-sdk-js';
import React from 'react';
import {isVersionGreaterThanOrEqual} from '../../helpers.ts/helpers';
import NotFound from '../NotFound';

export type PortTestStatus = 'success' | 'tcpBlocked' | 'udpBlocked' | 'loading' | 'queued' | 'failed';

export interface PortTest {
  status: PortTestStatus | undefined;
  tcpPort: string;
  udpPort: string;
}

export interface ResultsProps {
  connectStatus: string;
  currentVersion: string;
  latestVersion: string;
  installerURL: string;
  extensionURL: string;
  extensionFound: boolean;
  isCompatible: boolean;
  portTest: PortTest;
}

/**
* React Component
*
* The Connect test results
*
* @example
* ```javascript
* <Results />
* ```
*/
export default class Results extends React.Component<ResultsProps> {
  state: {
    loading: boolean;
    portTestIconType?: NotificationTypes;
    portTestText?: string;
    result: React.ReactNode;
  } = {
      loading: false,
      result: null,
    };

  private setAnalyticsClick = (action: string): void => {
    tracking.track('Started Process', {
      process: action,
      successFlag: true,
    });
  };

  private extensionInstall = (props: ResultsProps): React.ReactNode => {
    tracking.track('Started Process', {
      process: 'Views Diagnostic page',
      successFlag: false,
      data: {
        errorMessage: 'No Extension Found',
      },
    });

    return (
      <div>
        <p>{language.get('connectResults.connectExtensionNotDetected')}</p>
        <p>{language.get('connectResults.pleaseInstallExtension')}</p>
        <Button onClick={() => {
          this.setAnalyticsClick('Install Connect Extension' as const);
          openNewTab(props.extensionURL, true);
        }}>
          {language.get('connectResults.installExtension')}
        </Button>
      </div>
    );
  };

  private failed = (props: ResultsProps) => {
    return (
      <div>
        <NotFound extensionFound={props.extensionFound} />
        <Button onClick={() => {
          this.setAnalyticsClick('Install Connect' as const);
          openNewTab(props.installerURL, true);
        }}>
          {language.get('connectResults.installLatest')}
        </Button>
      </div>
    );
  };

  /*
   * Only 3.9+ users will see this state.
   */
  private outdated = (props: ResultsProps) => {
    return (
      <div>
        <p>{language.getStyled('connectResults.updateText', [props.latestVersion])}</p>
        <Button onClick={() => {
          this.setAnalyticsClick('Update Connect' as const);
          openNewTab(props.installerURL, true);
        }}>
          {language.get('connectResults.updateToLatest')}
        </Button>
      </div>
    );
  };

  private running = (props: ResultsProps) => {
    // NOTE: 3.11.1.47 should be treated as 3.11.1.58 (bug in the app version)
    if (props.currentVersion === '3.11.1.47') {
      props.currentVersion = '3.11.1.58';
    }

    const isLatest = isVersionGreaterThanOrEqual(props.currentVersion, props.latestVersion);
    Logger.log(`Current: ${props.currentVersion} Latest: ${props.latestVersion}`);
    Logger.log(`Is running Connect latest: ${isLatest}`);

    if (isLatest) {
      return (
        <div>
          <p>{language.getStyled('connectResults.runningText', [props.currentVersion])}</p>
        </div>
      );
    } else {
      return this.outdated(props);
    }
  };

  private setupView() {
    const {connectStatus, portTest} = this.props;

    Logger.debug(`Rendering result: ${connectStatus}`);
    let result;
    let portTestText: string | undefined;
    let portTestIconType: NotificationTypes | undefined;

    switch (connectStatus) {
      case Connect.STATUS.EXTENSION_INSTALL:
        result = this.extensionInstall(this.props);
        break;
      case Connect.STATUS.FAILED:
        result = this.failed(this.props);
        break;
      case Connect.STATUS.OUTDATED:
        result = this.outdated(this.props);
        break;
      case Connect.STATUS.RUNNING:
        result = this.running(this.props);
        break;
    }

    switch (portTest.status) {
      case undefined:
        portTestText = language.get('connectResults.untestedPorts');
        portTestIconType = 'warning';
        break;
      case 'success':
        portTestText = language.get('connectResults.workingPorts');
        portTestIconType = 'success';
        break;
      case 'tcpBlocked':
        portTestText = language.get('connectResults.tcpBlocked');
        portTestIconType = 'error';
        break;
      case 'udpBlocked':
        portTestText = language.get('connectResults.udpBlocked');
        portTestIconType = 'error';
        break;
      case 'loading':
        portTestIconType = 'info';
        portTestText = language.get('connectResults.loading');
        break;
      case 'queued':
        portTestIconType = 'warning';
        portTestText = language.get('connectResults.queued');
        break;
      case 'failed':
        portTestIconType = 'error';
        portTestText = language.get('connectResults.failed');
        break;
    }

    this.setState({portTestIconType, portTestText, result});
  }

  private get notificationViews(): React.ReactNode {
    const {portTestIconType, portTestText} = this.state;
    const {isCompatible} = this.props;

    return (
      <>
        <InlineNotification kind={isCompatible ? 'success' : 'error'} title={language.get(`connectResults.${isCompatible ? 'compatible' : 'incompatible'}`)} hideCloseButton={true} lowContrast={true} />
        {!!(portTestIconType && portTestText) && <InlineNotification kind={portTestIconType} title={portTestText} hideCloseButton={true} lowContrast={true} />}
      </>
    );
  }

  componentDidUpdate(previousProps: ResultsProps): void {
    const {connectStatus, portTest, currentVersion, latestVersion, installerURL, extensionURL, extensionFound, isCompatible} = this.props;

    if (
      previousProps.connectStatus !== connectStatus ||
      previousProps.portTest?.status !== portTest?.status ||
      previousProps.portTest?.udpPort !== portTest?.udpPort ||
      previousProps.portTest?.tcpPort !== portTest?.tcpPort ||
      previousProps.currentVersion !== currentVersion ||
      previousProps.latestVersion !== latestVersion ||
      previousProps.installerURL !== installerURL ||
      previousProps.extensionURL !== extensionURL ||
      previousProps.extensionFound !== extensionFound ||
      previousProps.isCompatible !== isCompatible
    ) {
      this.setupView();
    }
  }

  componentDidMount(): void {
    this.setupView();
  }

  render(): React.ReactNode {
    const {loading, result} = this.state;
    const {currentVersion} = this.props;

    return (
      <AsperaWrapper className='connect-init-results' disableWrapping={true} loading={loading}>
        {!!result && result}
        {!!currentVersion && this.notificationViews}
      </AsperaWrapper>
    );
  }
}
