import {AsperaWrapper, language, SubManager, tracking} from '@aspera-ui/starter';
import {Logger, Utils} from '@ibm-aspera/connect-sdk-js';
import Axios from 'axios-observable';
import React from 'react';

interface NotFoundProp {
  extensionFound: boolean;
}

/**
* React Component
*
* The Not Found component for reason connect not found
*
* @example
* ```javascript
* <NotFound />
* ```
*/
export default class NotFound extends React.Component<NotFoundProp> {
  state = {
    loading: true,
    status: '',
  };

  private numPorts = 11;
  private currentPort = 33003;
  private detectedVersion?: string;
  private subManager = new SubManager();

  private testLocal(port: number): void {
    const {extensionFound} = this.props;
    this.numPorts--;

    this.subManager.setSubscription(Axios.get('http://127.0.0.1:' + port + '/v5/connect/info/ping').subscribe({
      next: (response) => {
        Logger.debug(response);
        Logger.debug(`num ports is ${this.numPorts}`);

        if (extensionFound) {
          this.testVersion(port);
        } else {
          if (Utils.BROWSER.SAFARI) {
            Logger.debug('safari enable extension');
            this.setState({status: language.get('connectResults.safariNoExtension'), loading: false});
            tracking.track('Started Process', {
              process: 'Viewed Diagnostic Page',
              successFlag: false,
              data: {
                errorMessage: 'Enable Safari Extension',
              },
            });
          } else {
            Logger.debug('DNS');
            this.setState({status: language.get('connectResults.issueDNS'), loading: false});
            tracking.track('Started Process', {
              process: 'Viewed Diagnostic Page',
              successFlag: false,
              data: {
                errorMessage: 'DNS Error',
              },
            });
          }
        }
      },
      error: (error) => {
        Logger.error(error);
        Logger.error('port probably blocked');

        if (!this.numPorts) {
          if (extensionFound) {
            this.setState({status: language.get('connectResults.extensionNoConnect'), loading: false});
            tracking.track('Started Process', {
              process: 'Viewed Diagnostic Page',
              successFlag: false,
              data: {
                errorMessage: 'Extension Installed without Connect App',
              },
            });
          } else {
            if (Utils.BROWSER.SAFARI) {
              Logger.debug('safari enable extension');
              this.setState({status: language.get('connectResults.extensionNoConnect'), loading: false});
              tracking.track('Started Process', {
                process: 'Viewed Diagnostic Page',
                successFlag: false,
                data: {
                  errorMessage: 'Safari No Connect App Found',
                },
              });
            } else {
              this.setState({status: language.get('connectResults.noConnect'), loading: false});
              tracking.track('Started Process', {
                process: 'Viewed Diagnostic Page',
                successFlag: false,
                data: {
                  errorMessage: 'No Connect App Found',
                },
              });
            }
          }
        } else {
          this.testLocal(this.currentPort++);
        }
      },
    }));
  }

  private testVersion(port: number): void {
    // Extension installed and Connect running, then get version
    this.subManager.setSubscription(Axios.get('http://127.0.0.1:' + port + '/v5/connect/info/version').subscribe({
      next: (response) => {
        Logger.debug(response);

        if (response.status === 200) {
          if (/^3.8./i.test(response.data.version)) {
            Logger.debug('Did not detect 3.9.0+');
            this.detectedVersion = response.data.version;
            this.setState({status: 'extensionWithOldConnect', loading: false});
            tracking.track('Started Process', {
              successFlag: false,
              process: 'Viewed Diagnostic Page',
              data: {
                errorMessage: 'Extension Installed with Outdated Connect App',
              },
            });
          } else if (/^3.9./i.test(response.data.version)) {
            this.setState({status: language.get('connectResults.nativeHostIssue'), loading: false});
            tracking.track('Started Process', {
              successFlag: false,
              process: 'Viewed Diagnostic Page',
              data: {
                errorMessage: 'Native Host Issue',
              },
            });
          }
        }
      },
      error: (error) => {
        Logger.error(error);
      },
    }));
  }

  private get oldConnectExtension(): React.ReactNode {
    return (
      <p>{language.getStyled('connectResults.connectNotCompatibleExtension', [this.detectedVersion])}</p>
    );
  }

  componentDidMount(): void {
    this.testLocal(this.currentPort);
  }

  componentWillUnmount(): void {
    this.subManager.unsubscribeAll();
  }

  render() {
    const {loading, status} = this.state;

    return (
      <AsperaWrapper loading={loading} disableWrapping={true}>
        {status === 'extensionWithOldConnect' ? this.oldConnectExtension : <p>{status}</p>}
      </AsperaWrapper>
    );
  }
}
