import {AsperaError, AsperaPageHeaderProps, AsperaSlideOverPanel, AsperaWrapper, Button, ClickableTile, ConnectStatusTypes, ConnectTransfer, getIcon, getIconComponent, language, Link, ListItem, modal, ModalServiceProps, monitorTransfers, notification, openNewTab, setupTransferMonitor, startTransfer, SubManager, tracking, UnorderedList} from '@aspera-ui/starter';
import {Connect} from '@ibm-aspera/connect-sdk-js';
import {EventString} from '@ibm-aspera/connect-sdk-js/dist/esm/core/types';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {mergeMap, Subject} from 'rxjs';
import AllowConnectPrompt from '../../components/AllowConnectPrompt';
import DiagnosticResults from '../../components/DiagnosticResults';
import ErrorWindows18 from '../../components/ErrorWindows18';
import ExtensionInstalledNotWorking from '../../components/ExtensionInstalledNotWorking';
import HelpInstalling from '../../components/HelpInstalling';
import RestartConnectManually from '../../components/RestartConnectManually';
import Results, {PortTest, PortTestStatus, ResultsProps} from '../../components/Results';
import TryAnotherBrowser from '../../components/TryAnotherBrowser';
import {errorCodes, getSupportLinks} from '../../constants/constants';
import {checkConnectCompatibility, getNewDownloadTransferSpec} from '../../helpers.ts/helpers';
import ModalTransferSettings from '../../modals/ModalTransferSettings';
import './Home.scss';

/**
 * React component
 *
 * The landing page
 */
class Home extends React.Component<RouteComponentProps> {
  state = {
    activeSlide: undefined as {title: string, slideInContent: React.ReactNode} | undefined,
    loading: true,
    connectStatus: 'INITIALIZING' as ConnectStatusTypes,
    extensionFound: false,
    latestVersion: '',
    installerLink: '',
    currentVersion: '',
    lastDateString: '',
    testRunning: false,
    portTest: {
      status: undefined,
      tcpPort: '33001',
      udpPort: '33001',
    } as PortTest,
  };

  private subManager = new SubManager();
  private lastTransferId = '';
  private modalProps: ModalServiceProps = {
    modalHeading: language.get('settingsModal.header'),
  };

  private get pageHeaderProps(): AsperaPageHeaderProps {
    const {lastDateString} = this.state;

    return {
      pageTitle: language.get('connectTest.results'),
      breadcrumbs: [],
      pageSubTitle: lastDateString ? language.get('connectTest.lastDiagnosed', [language.getDate(lastDateString, 'medium')]) : undefined,
      actions: [
        {
          kind: 'tertiary',
          name: language.get('connectTest.reload'),
          icon: getIconComponent('Renew'),
          onClick: () => {
            window.location.reload();
          },
        },
      ],
    };
  }

  private get resultsProps(): ResultsProps {
    const {connectStatus, extensionFound, latestVersion, installerLink, currentVersion, portTest} = this.state;

    return {
      connectStatus: connectStatus,
      portTest: portTest,
      currentVersion: currentVersion,
      extensionFound: extensionFound,
      extensionURL: window.asperaTransfer?.asperaInstaller?.getExtensionStoreLink() || '',
      installerURL: installerLink,
      latestVersion: latestVersion,
      isCompatible: checkConnectCompatibility(currentVersion),
    };
  }

  private get connectTestArea(): React.ReactNode {
    const {loading, portTest} = this.state;

    return (
      <AsperaWrapper loading={loading} disableWrapping={true} className="logic-section connect-test-area">
        <Results {...this.resultsProps} />
        {!!portTest.status && <DiagnosticResults {...this.resultsProps} />}
        {this.testArea}
      </AsperaWrapper>
    );
  }

  private get otherOptions(): React.ReactNode {
    const {activeSlide} = this.state;

    const helperTiles: {title: string, slideInContent: React.ReactNode}[] = [
      {
        title: language.get('connectOtherOptions.helpInstalling'),
        slideInContent: <HelpInstalling />,
      },
      {
        title: language.get('connectOtherOptions.extensionInstalledNoWork'),
        slideInContent: <ExtensionInstalledNotWorking />,
      },
      {
        title: language.get('connectOtherOptions.tryAnotherBrowser'),
        slideInContent: <TryAnotherBrowser />,
      },
      {
        title: language.get('connectOtherOptions.restartConnectManually'),
        slideInContent: <RestartConnectManually />,
      },
      {
        title: language.get('connectOtherOptions.errorItem', [errorCodes.errorCode18]),
        slideInContent: <ErrorWindows18 />,
      },
      {
        title: language.get('connectOtherOptions.allowConnectPrompted'),
        slideInContent: <AllowConnectPrompt />,
      },
    ];

    return (
      <div className='logic-section connect-other-options'>
        <div className='sub-heading'>{language.get('connectOtherOptions.title')}</div>
        <div className='helper-tile--wrapper'>
          {helperTiles.map((tile, key) => {
            return (
              <ClickableTile
                key={key}
                className='helper-tile'
                onClick={(): void => {
                  this.setState({activeSlide: tile});
                }}
              >
                {tile.title}
              </ClickableTile>
            );
          })}
        </div>
        {!!activeSlide && <AsperaSlideOverPanel {...activeSlide} onClose={this.closeSlideOver} open={activeSlide ? true : false} slideInMode={true} size='large'>{activeSlide.slideInContent}</AsperaSlideOverPanel>}
      </div>
    );
  }

  private get helpSupportLinks(): React.ReactNode {
    return (
      <div className="logic-section connect-test-area">
        <div className="sub-heading">{language.get('supportLinks.helpSupport')}</div>
        <UnorderedList className="link-list">
          {getSupportLinks().map((item, key) => {
            return (
              <ListItem key={key}>
                <Link onClick={() => openNewTab(item.url, true)}>{item.name}</Link>
              </ListItem>
            );
          })}
        </UnorderedList>
      </div>
    );
  }

  private closeSlideOver = (): void => {
    this.setState({activeSlide: undefined});
  };

  private connectStatusEventListener = (_eventType: EventString, connectStatus: ConnectStatusTypes): void => {
    const finalFunctions = (currentVersion = '') => {
      window.asperaTransfer?.asperaInstaller?.installationJSON(response => {
        const data = response as {version: string; links: {hrefAbsolute: string; rel: string}[]};

        const setStateFunction = (extensionFound: boolean): void => {
          this.setState({
            loading: false,
            lastDateString: new Date().toISOString(),
            connectStatus,
            currentVersion,
            extensionFound,
            latestVersion: data.version || '',
            installerLink: (data.links || []).find(link => link.rel === 'enclosure-one-click')?.hrefAbsolute || '',
          });
        };

        window.asperaTransfer?.asperaInstaller?.isExtensionInstalled(1000, {
          success: () => {
            setStateFunction(true);
          },
          // eslint-disable-next-line
          timedout: () => {
            setStateFunction(false);
          },
        });
      });
    };

    if (window.asperaTransfer) {
      window.asperaTransfer.connectStatus = Connect.STATUS.RUNNING;
      window.asperaTransfer.connectStatusChange?.next(connectStatus);
    }

    if (connectStatus === Connect.STATUS.RUNNING) {
      if (window.asperaTransfer) {
        window.asperaTransfer.connectRunning = true;
      }

      setupTransferMonitor();
      window.asperaTransfer?.asperaWeb?.version({
        success: version => {
          finalFunctions(version.version);
        },
        error: () => {
          finalFunctions();
        },
      });
    } else if (connectStatus !== Connect.STATUS.INITIALIZING) {
      finalFunctions();
    }
  };

  private createModal = (): void => {
    const {portTest} = this.state;
    const modalInfo = modal.prepare();
    const disableSubject: Subject<boolean> = new Subject();

    this.subManager.setSubscription(modal.create(modalInfo.id, this.modalProps, <ModalTransferSettings modalClose={modalInfo.callback} transferSettings={{...portTest}} disableSubject={disableSubject} />, disableSubject.asObservable(), true).subscribe({
      next: data => {
        const {portTest} = this.state;
        const {tcpPort, udpPort} = data.data as {tcpPort: string, udpPort: string};

        this.setState({
          portTest: {
            status: portTest.status,
            tcpPort,
            udpPort,
          },
        });
      },
      error: () => {},
    }));
  };

  private startTransfer = (): void => {
    const {portTest} = this.state;
    this.setState({testRunning: true});
    this.updatePortTestStatus('loading' as const);

    this.subManager.setSubscription(getNewDownloadTransferSpec(portTest.tcpPort, portTest.udpPort).pipe(mergeMap(transferSpec => {
      return startTransfer(transferSpec, 'connect-test');
    })).subscribe({
      next: response => {
        this.lastTransferId = (response as {transfer_specs: [{aspera_connect_settings: {request_id: string}}]})?.transfer_specs[0]?.aspera_connect_settings?.request_id;
        notification.create('success', language.get('connectTest.transferStarted'));
        this.updatePortTestStatus('success' as const);
      },
      error: error => {
        new AsperaError('Home: unable to start transfer', error);
        notification.create('error', language.get('connectTest.unableToGetTransfer'));
        this.setState({testRunning: false});
        this.lastTransferId = '';
        this.updatePortTestStatus('failed' as const);
      },
    }));
  };

  private get testArea(): React.ReactNode {
    const {testRunning, connectStatus} = this.state;
    const disabled = connectStatus !== Connect.STATUS.RUNNING || testRunning;

    return (
      <>
        <Button disabled={disabled} onClick={this.startTransfer}>{language.get('connectTest.beginTest')}</Button>
        <Button
          kind='ghost'
          onClick={(): void => {
            this.createModal();
          }}>{getIcon('Settings')}</Button>
      </>
    );
  }

  private updatePortTestStatus = (status: PortTestStatus): void => {
    const {portTest} = this.state;

    this.setState({
      portTest: {
        udpPort: portTest.udpPort,
        tcpPort: portTest.tcpPort,
        status,
      },
    });
  };

  componentDidMount(): void {
    if (window.asperaTransfer?.asperaWeb) {
      window.asperaTransfer.asperaWeb.addEventListener(Connect.EVENT.STATUS, this.connectStatusEventListener);
      window.asperaTransfer.asperaWeb.initSession();
    }

    this.subManager.setSubscription(monitorTransfers().subscribe({
      next: transferData => {
        const transfer = (transferData.transfers as ConnectTransfer[]).find(transfer => (transfer as unknown as {aspera_connect_settings: {request_id: string}})?.aspera_connect_settings?.request_id === this.lastTransferId);

        const checkTransferError = (code: number): void => {
          switch (code) {
            case 44:
              this.updatePortTestStatus('tcpBlocked' as const);
              break;
            case 15:
            case 14:
              this.updatePortTestStatus('udpBlocked' as const);
              break;
            default:
              this.updatePortTestStatus('failed' as const);
              break;
          }
        };

        if (transfer) {
          switch (transfer.status) {
            case Connect.TRANSFER_STATUS.COMPLETED:
              tracking.track('Started Process', {
                successFlag: true,
                process: 'Diagnosed Connectivity',
              });

              this.updatePortTestStatus('success' as const);
              this.setState({testRunning: false});
              this.lastTransferId = '';
              break;
            case Connect.TRANSFER_STATUS.FAILED:
              tracking.track('Started Process', {
                successFlag: false,
                process: 'Diagnosed Connectivity',
              });

              this.setState({testRunning: false});
              this.lastTransferId = '';
              checkTransferError(transfer.error_code);
              break;
            case Connect.TRANSFER_STATUS.QUEUED:
              this.updatePortTestStatus('queued' as const);
              break;
          }
        }
      },
      error: error => {
        new AsperaError('Home: Unable to monitor transfers', error);
      },
    }));
  }

  componentWillUnmount(): void {
    this.subManager.unsubscribeAll();
  }

  render(): React.ReactNode {
    return (
      <AsperaWrapper className="home" pageHeaderProps={this.pageHeaderProps}>
        {this.connectTestArea}
        {this.otherOptions}
        {this.helpSupportLinks}
      </AsperaWrapper>
    );
  }
}

export default withRouter(Home);
