import {AsperaCodeSnippet, language} from '@aspera-ui/starter';
import React from 'react';
import {ResultsProps} from '../Results';
import './DiagnosticResults.scss';

/**
* React Component
*
* The Diagnostic Results for other options to try
*
* @example
* ```javascript
* <DiagnosticResults />
* ```
*/
export default class DiagnosticResults extends React.Component<ResultsProps> {

  private get text(): string {
    const {connectStatus, portTest, currentVersion, extensionFound, isCompatible} = this.props;

    return `
${language.get('diagnosticResults.system')}
${language.get('diagnosticResults.userAgent', [window.navigator.userAgent])}

${language.get('diagnosticResults.diagnostics')}
${language.get('diagnosticResults.connectVersion', [currentVersion])}
${language.get('diagnosticResults.connectStatus', [connectStatus])}
${language.get('diagnosticResults.compatible', [isCompatible ? language.get('diagnosticResults.yes') : language.get('diagnosticResults.no')])}
${language.get('diagnosticResults.detectedExtension', [extensionFound ? language.get('diagnosticResults.yes') : language.get('diagnosticResults.no')])}

${language.get('diagnosticResults.firewallTest')}
${language.get('diagnosticResults.result', [portTest?.status || '?'])}
${language.get('diagnosticResults.ports', [portTest?.tcpPort || '?', portTest?.udpPort || '?'])}
    `;
  }

  render(): React.ReactNode {
    return (
      <div className="help-content diagnostic-results">
        <p className="help-content--text">{language.get('diagnosticResults.main')}</p>
        <AsperaCodeSnippet type="multi" value={this.text} />
      </div>
    );
  }
}
