import {language} from '@aspera-ui/starter';
import React from 'react';

/**
* React Component
*
* The Try another browser for other options to try
*
* @example
* ```javascript
* <TryAnotherBrowser />
* ```
*/
export default class TryAnotherBrowser extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="help-content">
        <p className="help-content--text">{language.get('tryAnotherBrowser.main')}</p>
      </div>
    );
  }
}
