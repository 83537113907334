import {language, LanguageType} from '@aspera-ui/starter';

/**
 * Map of languages that are supported in Aspera on Cloud.
 * Ordered by language code.
 * English languages are there for date format. But are not translated currently (not shown to user).
 */
export const availableLanguages: Map<string, LanguageType> = new Map([
  ['en-us', {
    code: 'en-us',
    name: 'English (United States)',
    dateFormat: 'm/d/Y',
    npsKey: 'en',
  }],
  ['en-au', {
    code: 'en-au',
    name: 'English (Australia)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-bz', {
    code: 'en-bz',
    name: 'English (Belize)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-ca', {
    code: 'en-ca',
    name: 'English (Canada)',
    hidden: true,
    dateFormat: 'Y-m-D',
    npsKey: 'en',
  }],
  ['en-gb', {
    code: 'en-gb',
    name: 'English (United Kingdom)',
    hidden: true,
    dateFormat: 'd/m/Y',
    timeFormat24: true,
    npsKey: 'en',
  }],
  ['en-ie', {
    code: 'en-ie',
    name: 'English (Ireland)',
    hidden: true,
    dateFormat: 'd/m/Y',
    timeFormat24: true,
    npsKey: 'en',
  }],
  ['en-jm', {
    code: 'en-jm',
    name: 'English (Jamaica)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-nz', {
    code: 'en-nz',
    name: 'English (New Zealand)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-ph', {
    code: 'en-ph',
    name: 'English (Republic of the Philippines)',
    hidden: true,
    dateFormat: 'm/d/Y',
    npsKey: 'en',
  }],
  ['en-tt', {
    code: 'en-tt',
    name: 'English (Trinidad and Tobago)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
  ['en-za', {
    code: 'en-za',
    name: 'English (South Africa)',
    hidden: true,
    dateFormat: 'Y-m-d',
    npsKey: 'en',
  }],
  ['en-zw', {
    code: 'en-zw',
    name: 'English (Zimbabwe)',
    hidden: true,
    dateFormat: 'd/m/Y',
    npsKey: 'en',
  }],
]);

/**
 * Map of browser and Connect versions that are compatible by OS. These are minimums,
 * and generally anything higher than what is listed here is compatible. When a breaking
 * change occurs add a new entry below.
 */

type BrowserMatrix = {
  [key: string]: {
    [key: string]: string | number[];
  }[]
}

export const browserMatrix: BrowserMatrix = {
  linux: [
    {
      connectVersion: '3.9.0',
      chrome: [64],
      firefox: [57],
    },
  ],
  // eslint-disable-next-line spellcheck/spell-checker
  macos: [
    {
      connectVersion: '3.9.0',
      chrome: [64],
      edge: [84],
      firefox: [57],
      safari: [12],
    },
  ],
  windows: [
    {
      connectVersion: '4.0.0',
      chrome: [64],
      edge: [84],
      firefox: [57],
    },
    {
      connectVersion: '3.9.0',
      chrome: [64],
      edge: [84],
      firefox: [57],
      ie: [11],
    },
  ],
};

export const externalLinks = {
  deployConnectExtension: 'https://download.asperasoft.com/download/docs/connect/3.9.8/user_linux/webhelp/index.html#dita/extension_in_locked_env.html',
  firewallConsiderations: 'https://www.ibm.com/support/pages/node/746389?_ga=2.196519599.2040087237.1653508470-1824782186.1653346511',
  performanceIssues: 'https://www.ibm.com/support/pages/node/746431?_ga=2.196519599.2040087237.1653508470-1824782186.1653346511',
  connectDocumentation: 'https://www.ibm.com/docs/en/aspera-connect',
  compatibility: 'https://www.ibm.com/docs/en/aspera-connect',
  supportSite: 'https://www.ibm.com/mysupport?_ga=2.221022107.2040087237.1653508470-1824782186.1653346511',
  unblockChrome: 'https://www.ibm.com/support/pages/node/746941?_ga=2.236631682.2079597598.1653515824-1378526923.1653515824',
  fireFoxAllow: 'https://www.ibm.com/support/pages/node/746949?_ga=2.236631682.2079597598.1653515824-1378526923.1653515824',
  safariDownload: 'https://www.ibm.com/support/pages/node/747443?_ga=2.47414948.2079597598.1653515824-1378526923.1653515824',
};

export const getSupportLinks = (): {url: string; name: string; noOption?: boolean}[] => {
  return [
    {
      url: externalLinks.firewallConsiderations,
      name: language.get('supportLinks.firewallConsiderations'),
    },
    {
      url: externalLinks.performanceIssues,
      name: language.get('supportLinks.identifyingPerformanceIssues'),
      noOption: true,
    },
    {
      url: externalLinks.connectDocumentation,
      name: language.get('supportLinks.connectDocumentation'),
    },
    {
      url: externalLinks.compatibility,
      name: language.get('supportLinks.compatibility'),
    },
    {
      url: externalLinks.supportSite,
      name: language.get('supportLinks.supportSite'),
    },
  ];
};

export const errorCodes = {
  // eslint-disable-next-line
  errorCode18: '0xc0000018',
};

const defaults = {
  availableLanguages,
};

export default defaults;
