import {language} from '@aspera-ui/starter';
import '@aspera-ui/starter/styles/aspera-core-global.scss';
import '@aspera-ui/starter/styles/aspera-global.scss';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {availableLanguages} from './constants/constants';
import englishFull from './constants/en-us';
import App from './views/App';

language.initDictionary(englishFull, availableLanguages);
const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error('Index: Could not load app', {container});
}
