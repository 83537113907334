import {AsperaAppWrapper, AsperaError, AsperaErrorStateProps, AsperaRouteMonitor, AsperaStrictMode, AsperaTransferSystemData, BasicAppType, BasicUserType, downloadSupportData, getIconComponent, language, modal, openAppInfoModal, openNewTab, SubManager, tracking} from '@aspera-ui/starter';
import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {Subject} from 'rxjs';
import {getSupportLinks} from '../../constants/constants';
import {getAllRoutes} from '../../routes';
import './App.scss';

/**
 * React component
 *
 * The primary entry point of the application
 *
 * @example
 * ```javascript
 * <App>
 *   <h1>All app children here</h1>
 * </App>
 * ```
 */
class App extends React.Component {
  state = {
    loading: false,
    error: false,
  };

  private subManager = new SubManager();
  private sessionEnd: Subject<string | undefined> = new Subject();
  private appSetup = false;
  private homeApp: BasicAppType = {
    id: undefined,
    route: '/',
    displayName: '',
  };

  private basicUser: BasicUserType = {
    isIbmIdUser: false,
    displayUserRole: language.get('appInfo.user'),
    displayName: language.get('appInfo.menu'),
  };

  private get errorProps(): AsperaErrorStateProps {
    const errorLinks = [
      {
        name: language.get('appInfo.getDebuggingInfo'),
        onClick: () => {
          openAppInfoModal(this.subManager);
        },
      },
      {
        name: language.get('header.downloadSupportData'),
        onClick: () => {
          downloadSupportData();
        },
      },
    ];

    return {
      type: '0',
      title: language.get('appInfo.criticalError'),
      body: language.get('appInfo.criticalErrorHelp'),
      description: language.get('appInfo.criticalErrorDescription'),
      links: errorLinks,
    };
  }

  private get transferSystemData(): AsperaTransferSystemData {
    return {
      skipWelcome: true,
    };
  }

  componentWillUnmount(): void {
    this.subManager.unsubscribeAll();
  }

  componentDidCatch(error: unknown): void {
    new AsperaError('App: Encountered critical error', error);
    modal.removeAll();
    this.setState({loading: false, error: true});
  }

  componentDidMount(): void {
    if (!this.appSetup) {
      tracking.setUserPreferences({
        hideChangeDownloadLocationAd: true,
        skipConnectWelcomeScreen: true,
        suppressMobileMessage: true,
      });
      this.appSetup = true;
    }
  }

  render(): React.ReactNode {
    const {loading, error} = this.state;

    return (
      <Router>
        <AsperaStrictMode>
          <Switch>
            <AsperaAppWrapper disableConnectInstaller={true} className="aspera-root-app" productName={language.get('appInfo.asperaConnectDiagnostic')} errorProps={this.errorProps} transferSystemData={this.transferSystemData} loading={loading} error={error} sessionTerminationHandle={this.sessionEnd.asObservable()}>
              {(!loading && !error) &&
                <AsperaRouteMonitor
                  productName={language.get('appInfo.asperaConnectDiagnostic')}
                  currentUser={this.basicUser}
                  currentApp={this.homeApp}
                  getAvailableAppById={() => this.homeApp}
                  allRoutes={getAllRoutes()}
                  hideAppSwitcher={true}
                  hideLeftNav={true}
                  customUserIcon={getIconComponent('Menu', 20)}
                  newAppListener={() => {}}
                  availableApps={[
                    {
                      route: '/',
                      displayName: '',
                      description: '',
                      icon: getIconComponent('Home'),
                    },
                  ]}
                  helpOptions={getSupportLinks().filter(item => !item.noOption).map(item => {
                    return {
                      displayName: item.name,
                      route: item.url,
                      onClick: () => openNewTab(item.url, true),
                    };
                  })}
                />
              }
            </AsperaAppWrapper>
          </Switch>
        </AsperaStrictMode>
      </Router>
    );
  }
}

export default App;
