import {language} from '@aspera-ui/starter';
import React from 'react';

/**
* React Component
*
* The Error for Windows ending in 18 for other options to try
*
* @example
* ```javascript
* <ErrorWindows18 />
* ```
*/
export default class ErrorWindows18 extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="help-content">
        <p className="help-content--text">{language.get('errorWindows18.main')}</p>
      </div>
    );
  }
}
