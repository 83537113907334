import {language} from '@aspera-ui/starter';
import React from 'react';
import {externalLinks} from '../../constants/constants';

/**
* React Component
*
* The Help Installing content for other options to try
*
* @example
* ```javascript
* <HelpInstalling />
* ```
*/
export default class HelpInstalling extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="help-content">
        <p className="help-content--text">{language.get('helpInstalling.main')}</p>
        <p className="help-content--text">{language.getStyled('helpInstalling.secondary', undefined, [externalLinks.deployConnectExtension])}</p>
      </div>
    );
  }
}
