import {language, ListItem, UnorderedList} from '@aspera-ui/starter';
import React from 'react';

/**
* React Component
*
* The Restart Connect manually for other options to try
*
* @example
* ```javascript
* <RestartConnectManually />
* ```
*/
export default class RestartConnectManually extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="help-content">
        <p className="help-content--text">{language.get('restartConnectManually.main')}</p>
        <p className="help-content--text bold-text">{language.get('restartConnectManually.mac')}</p>
        <UnorderedList className="link-list">
          <ListItem>{language.get('restartConnectManually.mac1')}</ListItem>
          <ListItem>{language.getStyled('restartConnectManually.mac2')}</ListItem>
        </UnorderedList>
        <p className="help-content--text bold-text">{language.get('restartConnectManually.windows')}</p>
        <UnorderedList className="link-list">
          <ListItem>{language.get('restartConnectManually.windows1')}</ListItem>
        </UnorderedList>
        <p className="help-content--text bold-text">{language.get('restartConnectManually.linux')}</p>
        <UnorderedList className="link-list">
          <ListItem>{language.get('restartConnectManually.linux1')}</ListItem>
          <ListItem>{language.getStyled('restartConnectManually.linux2')}</ListItem>
        </UnorderedList>
      </div>
    );
  }
}
