import {AsperaError, TransferSpec} from '@aspera-ui/starter';
import Axios from 'axios-observable';
import {parse} from 'bowser';
import {compare} from 'compare-versions';
import {map, Observable} from 'rxjs';
import {browserMatrix} from '../constants/constants';

export const isVersionGreaterThanOrEqual = (current: string, latest: string): boolean => {
  return compare(current, latest, '>=');
};

/**
 * Determine if Connect version is compatible with browser
 */
export const checkConnectCompatibility = (connectFullVersion: string): boolean => {
  const os = getOS();
  const browser = getBrowser();

  if (!os || !browser || !browserMatrix[os] || !connectFullVersion) {
    return false;
  }

  const currentConnectVersion = connectFullVersion.split('.').slice(0, 3).join('.');
  const baseVersions = browserMatrix[os];

  /**
   * Iterate over the current operating system's "base" Connect versions that it supports.
   * The base versions are stored in descending order, so if the current Connect version is greater
   * than or equal to the base version, then compare the current browser with the base version's
   * supported browsers. If the current Connect version is less than the base version, then
   * iterate to the next base version. If we run out of base versions, for example if the current
   * Connect version is really low, then return false.
   */
  for (const baseVersion of baseVersions) {
    if (isVersionGreaterThanOrEqual(currentConnectVersion, baseVersion.connectVersion as string) && baseVersion[browser.name]) {
      const baseBrowserVersions = baseVersion[browser.name];

      if ((baseBrowserVersions.length === 1 && browser.version >= baseBrowserVersions[0]) || (baseBrowserVersions.length === 2 && browser.version >= baseBrowserVersions[0] && browser.version <= baseBrowserVersions[1])) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Get current browser name
 */
export const getBrowser = (): {name: string, version: number} | undefined => {
  const userAgent = parse(window.navigator.userAgent);
  const {version} = userAgent.browser;

  let {name} = userAgent.browser;
  name = name?.toLowerCase();

  if (name === 'internet explorer') {
    name = 'ie';
    // eslint-disable-next-line spellcheck/spell-checker
  } else if (name === 'microsoft edge') {
    name = 'edge';
  }

  return (name && version) ? {name, version: parseFloat(version)} : undefined;
};

/**
 * Get current OS name
 */
export const getOS = (): string | undefined => {
  const userAgent = parse(window.navigator.userAgent);
  let os = userAgent.os.name?.toLowerCase();

  // eslint-disable-next-line spellcheck/spell-checker
  if (os === 'ubuntu') {
    os = 'linux';
  }

  return os;
};

/**
 * Get a download transfer spec for testing
 *
 * @param tcpPort - The TCP (ssh) port to use
 * @param udpPort - The UDP (fasp) port to use
 *
 * @returns - Observable that should return a transferSpec ready for use
 */
export const getNewDownloadTransferSpec = (tcpPort: number | string, udpPort: number | string): Observable<TransferSpec> => {
  const body = {
    transfer_requests: [{
      transfer_request: {
        paths: [{source: 'test_file'}],
      },
    }],
  };

  return Axios.post('/files/download_setup', body).pipe(map(response => {
    if (Array.isArray(response.data?.transfer_specs) && response.data?.transfer_specs?.length) {
      const transferSpec = response.data.transfer_specs[0].transfer_spec as TransferSpec;
      transferSpec.authentication = 'token';
      transferSpec.ssh_port = Number(tcpPort);
      transferSpec.fasp_port = Number(udpPort);

      return transferSpec;
    } else {
      throw new AsperaError('getNewDownloadTransferSpec: did not return a transferSpec', {response}).getError();
    }
  }));
};
