import {language, ListItem, UnorderedList} from '@aspera-ui/starter';
import React from 'react';

/**
* React Component
*
* The Extension is installed but connect does not work other options to try
*
* @example
* ```javascript
* <ExtensionInstalledNotWorking />
* ```
*/
export default class ExtensionInstalledNotWorking extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="help-content">
        <p className="help-content--text">{language.get('extensionInstalledNotWorking.intro')}</p>
        <p className="help-content--text">{language.getStyled('extensionInstalledNotWorking.usingWindows')}</p>
        <p className="help-content--text">{language.getStyled('extensionInstalledNotWorking.usingChrome')}</p>
        <UnorderedList className="link-list">
          <ListItem>{language.getStyled('extensionInstalledNotWorking.copyUrl')}</ListItem>
          <ListItem>{language.get('extensionInstalledNotWorking.goToWebsite')}</ListItem>
          <ListItem>{language.get('extensionInstalledNotWorking.connectRunningNote')}</ListItem>
        </UnorderedList>
      </div>
    );
  }
}
