import {language} from '@aspera-ui/starter';
import React from 'react';
import {externalLinks} from '../../constants/constants';
import './AllowConnectPrompt.scss';
import chromeEPR from './images/chrome_epr.jpg';
import edgeAllow from './images/edge_allow.jpg';
import fireFoxAllow from './images/firefox_allow.jpg';
import fireFoxAllow2 from './images/firefox_allow2.jpg';
import safariAllow from './images/safari_allow.jpg';

/**
* React Component
*
* The Allow Connect if prompted for other options to try
*
* @example
* ```javascript
* <AllowConnectPrompt />
* ```
*/
export default class AllowConnectPrompt extends React.Component {

  private getImageItem(image: string, altText: string): React.ReactNode {
    return <img className="image-item" alt={altText} src={image} />;
  }

  render(): React.ReactNode {
    return (
      <div className="help-content allow-connect-prompt">
        <p className="help-content--text">{language.get('allowConnectPrompt.main')}</p>
        <p className="help-content--text bold-text">{language.get('allowConnectPrompt.chrome')}</p>
        {this.getImageItem(chromeEPR, language.get('allowConnectPrompt.chromeExample'))}
        <p className="help-content--text">{language.getStyled('allowConnectPrompt.chromeHelp', undefined, [externalLinks.unblockChrome])}</p>
        <p className="help-content--text bold-text">{language.get('allowConnectPrompt.fireFox')}</p>
        {this.getImageItem(fireFoxAllow, language.get('allowConnectPrompt.fireFoxExample'))}
        {this.getImageItem(fireFoxAllow2, language.get('allowConnectPrompt.fireFoxExample'))}
        <p className="help-content--text">{language.getStyled('allowConnectPrompt.fireFoxHelp', undefined, [externalLinks.fireFoxAllow])}</p>
        <p className="help-content--text bold-text">{language.get('allowConnectPrompt.internetExplorer')}</p>
        <p className="help-content--text">{language.getStyled('allowConnectPrompt.internetExplorerHelp', undefined, [externalLinks.fireFoxAllow])}</p>
        <p className="help-content--text bold-text">{language.get('allowConnectPrompt.edge')}</p>
        {this.getImageItem(edgeAllow, language.get('allowConnectPrompt.edgeExample'))}
        <p className="help-content--text">{language.getStyled('allowConnectPrompt.edgeHelp')}</p>
        <p className="help-content--text bold-text">{language.get('allowConnectPrompt.safari')}</p>
        {this.getImageItem(safariAllow, language.get('allowConnectPrompt.safariExample'))}
        <p className="help-content--text">{language.getStyled('allowConnectPrompt.safariHelp', undefined, [externalLinks.safariDownload])}</p>
      </div>
    );
  }
}
