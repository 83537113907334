const defaults = {
  appInfo: {
    asperaConnectDiagnostic: 'Aspera Connect Diagnostic Tool',
    criticalError: 'An error occurred.',
    criticalErrorHelp: 'Try your action again.',
    criticalErrorDescription: 'If the problem persists, consider the options below:',
    goHome: 'Return to home page',
    contactSupport: 'Contact support',
    getDebuggingInfo: 'Access debugging information',
    user: 'User',
    menu: 'Menu',
  },
  connectTest: {
    results: 'Results',
    reload: 'Reload',
    lastDiagnosed: 'Last diagnosed $0',
    beginTest: 'Begin test',
    unableToGetTransfer: 'Unable to get transfer authorization',
    transferStarted: 'Transfer started',
  },
  settingsModal: {
    header: 'Transfer Settings',
    tcpLabel: 'TCP Port',
    udpLabel: 'UDP Port',
  },
  connectOtherOptions: {
    title: 'Other options to try',
    transferTest: 'Aspera transfer test',
    helpInstalling: 'Help installing the extension',
    extensionInstalledNoWork: 'Extension is installed but Connect does not work',
    tryAnotherBrowser: 'Try another browser',
    restartConnectManually: 'Restart Connect manually',
    errorItem: 'Error $0',
    allowConnectPrompted: 'Allow Connect if prompted',
    diagnosticResults: 'Diagnostic results',
  },
  supportLinks: {
    helpSupport: 'Help and support',
    firewallConsiderations: 'Firewall considerations',
    identifyingPerformanceIssues: 'Identifying and understanding performance bottlenecks with Aspera Transfers',
    connectDocumentation: 'Connect documentation',
    compatibility: 'Compatibility',
    supportSite: 'Support site',
  },
  helpInstalling: {
    main: 'Some users are unable to install the required Connect extension due to restrictions in enterprise environments. Users can either contact their administrator to whitelist the Connect extension or install the extension manually.',
    secondary: 'For more information, check out: $l/Deploying the Connect extension/$l',
  },
  connectResults: {
    compatible: 'Your version of Connect is compatible with your browser.',
    incompatible: 'Your version of Connect may not be compatible with your browser.',
    queued: 'Transfer is queued...',
    loading: 'Loading...',
    failed: 'Transfer failed for unknown reason',
    udpBlocked: 'Your UDP ports are likely blocked.',
    tcpBlocked: 'Your TCP ports are likely blocked.',
    workingPorts: 'Aspera transfer test completed successfully.',
    untestedPorts: 'You have not run the Aspera transfer test.',
    runningText: 'Your Connect $b/$0/$b is at the latest version.',
    updateText: 'Connect $b/$0/$b is available.',
    updateToLatest: 'Update to the latest Connect',
    installLatest: 'Install latest version of Connect',
    installExtension: 'Install Connect Extension',
    connectExtensionNotDetected: 'The Connect extension was not detected on your browser.',
    pleaseInstallExtension: 'Please install the latest Connect extension.',
    connectNotCompatibleExtension: 'The Connect extension was detected on your browser, but your Connect $b/$0/$b is not compatible. Upgrade to the latest version.',
    safariNoExtension: 'Connect is running on your system but cannot communicate with the browser. Check if the extension is enabled: Safari > Preferences > Extensions',
    issueDNS: 'Connect is running on your system but cannot communicate with the browser. This is likely due to your DNS being unable to resolve local.connectme.us, or certificate issues for this domain.',
    extensionNoConnect: 'The Connect extension was detected on your browser but the Connect application was not detected on your system. If Connect is already installed then make sure it is at the latest version.',
    safariNoConnect: 'Connect was not detected on your system. If Connect is installed, enable the extension: Safari > Preferences > Extensions',
    noConnect: "Connect was not detected on your system. If Connect is installed and running on your system, make sure a firewall/anti-virus system isn't blocking Connect communication.",
    nativeHostIssue: 'The Connect extension was detected on your browser, but could not communicate with the Connect application. Try restarting Connect and the browser.',
  },
  extensionInstalledNotWorking: {
    intro: 'If the extension is installed, but Connect still does not work, this could indicate a permissions issue with the user running Connect.',
    usingWindows: 'If using $b/Windows/$b, check if you can launch $m/cmd.exe/$m: Start menu > enter "Command Prompt" > Command Prompt',
    usingChrome: 'If a command prompt window appears saying it has been "disabled by your administrator", this will cause the web extension to fail. In this case, if you are using using $b/Chrome/$b, it is recommended to do the following:',
    copyUrl: 'Copy this URL to the clipboard: $m/javascript:(function(){localStorage["aspera-connect-method"]="http",window.location.reload(); })()/$m',
    goToWebsite: 'Go to your website',
    typeJavascript: "Type $m/javascript/$m: into the address bar, paste the URL, and click 'Enter'",
    connectRunningNote: 'Note: Connect needs to be running for this to work.',
  },
  tryAnotherBrowser: {
    main: 'For various reasons, Connect may not be recognized in a certain browser. To help with troubleshooting, try using Connect in another browser.',
  },
  errorWindows18: {
    main: 'This error is returned by Windows when it fails to load some DLLs. This is most commonly caused by FIPS mode on Windows. Try a system reboot or installing the latest version of Connect.',
  },
  restartConnectManually: {
    main: 'In some cases, issues can be fixed by restarting Connect. Quit Connect, then re-launch it manually:',
    mac: 'macOS',
    windows: 'Windows',
    linux: 'Linux',
    mac1: 'System-wide: Macintosh HD > Applications > Aspera Connect',
    mac2: 'Per-user: Macintosh HD > Users > $i/home_dir/$i > Applications > Aspera Connect',
    windows1: 'Start menu > enter "Aspera" > IBM Aspera Connect',
    linux1: "Launch Connect from the system's applications menu",
    linux2: 'Or, launch Connect from the command line: $m/~/.aspera/connect/bin/asperaconnect/$m',
  },
  diagnosticResults: {
    main: 'Copy your results to paste into your Connect-related tickets with IBM Aspera Support.',
    system: 'SYSTEM',
    userAgent: 'User agent: $0',
    diagnostics: 'DIAGNOSTICS',
    connectVersion: 'Connect version: $0',
    connectStatus: 'Connect status: $0',
    compatible: 'Compatible with browser: $0',
    detectedExtension: 'Detected extension: $0',
    firewallTest: 'FIREWALL TEST',
    result: 'Result: $0',
    ports: 'Ports: TCP $0/UDP $1',
    yes: 'YES',
    no: 'NO',
  },
  allowConnectPrompt: {
    main: 'Sometimes your browser will prompt you to "allow" Connect or the Connect plugin before Connect can launch.',
    chrome: 'Chrome',
    chromeHelp: 'If you selected Do Nothing, see this Knowledge Base article: $l/How to unblock the launching of Connect 3.6.5+ in Chrome/$l.',
    fireFox: 'Firefox',
    fireFoxHelp: 'If you blocked Aspera Web, see the process for unblocking in this Knowledge Base article: $l/How to set Firefox to always allow Connect/$l.',
    internetExplorer: 'Internet Explorer',
    internetExplorerHelp: 'If you disabled Aspera Web in the browser, see the process for re-enabling it in this Knowledge Base article: $l/"Webpage Error" pops up on Internet Explorer when trying to use Connect/$l.',
    safari: 'Safari',
    safariHelp: 'If you blocked Aspera Web, see the process for unblocking in this Knowledge Base article: $l/Downloads button not present when using the Connect Plug-In on Connect Server/Faspex/Shares/$l',
    edge: 'Edge',
    edgeHelp: 'If you selected $b/No/$b, refresh the page and select $b/Yes/$b when prompted again.',
    chromeExample: 'Chrome example',
    fireFoxExample: 'Firefox example',
    edgeExample: 'Edge example',
    safariExample: 'Safari example',
  },
};

export default defaults;
