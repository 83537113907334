import {AsperaRoute} from '@aspera-ui/starter';
import Home from '../views/Home';

/**
 * All routes for the application
 */
export const getAllRoutes = (): AsperaRoute[] => {
  return [
    {
      path: '/',
      component: Home,
      exact: true,
    },
  ];
};

const defaults = {
  getAllRoutes,
};

export default defaults;
