import {AsperaForm, AsperaFormType, language, SubManager} from '@aspera-ui/starter';
import React from 'react';
import {Subject} from 'rxjs';

export type ModalTransferSettingsResponse = {
  tcpPort: string;
  udpPort: string;
}

export type ModalTransferSettingsProps = {
  modalClose: (data: ModalTransferSettingsResponse) => void;
  transferSettings: ModalTransferSettingsResponse;
  disableSubject: Subject<boolean>;
}

/**
 * React component
 *
 * The TransferSettingsModal provides a way to update the transfer settings such as the
 * TCP and UDP ports being used.
 */
class ModalTransferSettings extends React.Component<ModalTransferSettingsProps> {
  private settings = {
    tcpPort: '',
    udpPort: '',
  };

  private subManager = new SubManager();
  private validFormSubject: Subject<boolean> = new Subject();

  private get formDetails(): AsperaFormType {
    const {transferSettings} = this.props;
    this.settings = {...transferSettings};

    const tcpPorts = [{text: '22', value: '22'}, {text: '33001', value: '33001'}];
    const udpPorts = [{text: '33001', value: '33001'}];

    return {
      id: 'connect-test-settings',
      model: this.settings,
      items: [
        {type: 'select', pointer: 'tcpPort', required: true, label: language.get('settingsModal.tcpLabel'), items: tcpPorts},
        {type: 'select', pointer: 'udpPort', required: true, label: language.get('settingsModal.udpLabel'), items: udpPorts},
      ],
    };
  }

  componentDidMount(): void {
    const {disableSubject} = this.props;

    this.subManager.setSubscription(this.validFormSubject.subscribe({
      next: valid => {
        disableSubject.next(!valid);
      },
      error: () => {},
    }));
  }

  componentWillUnmount(): void {
    const {modalClose} = this.props;

    this.subManager.unsubscribeAll();
    modalClose(this.settings);
  }

  render(): React.ReactNode {
    return <AsperaForm formDetails={this.formDetails} validFormSubject={this.validFormSubject} />;
  }
}

export default ModalTransferSettings;
